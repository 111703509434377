import React from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './HomeTemplate.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { StringUtils } from '../../../utils/StringUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { PageTypes } from '../../constants/Pages';
import { IGame } from '../../models/Game/Game';
import { CategorySection, GamesListFn } from '../../models/GamesList';
import { CategoryLocalizedRoute, CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../models/PagesData';
import { AdDividerPromoCard } from '../../molecules/Cards/AdDividerPromoCard/AdDividerPromoCard';
import { SeoCard, SeoCardIllustration } from '../../molecules/Cards/SeoCard/SeoCard';
import { GameCardTypes } from '../../molecules/GameCard/GameCard';
import HomepageSlider from '../../molecules/HomepageSlider/HomepageSlider';
import { PromoCard } from '../../molecules/PromoCard/PromoCard';
import { HomepageCarousel } from '../../organisms/HomepageCarousel/HomepageCarousel';
import { Analytics } from '../../services/Analytics/Analytics';
import { DeviceType } from '../../services/DeviceDetector';
import { GameService } from '../../services/GameService';
import { PageService } from '../../services/PageService';
import { PWAService } from '../../services/PWAService';
import { UrlService } from '../../services/UrlService';
import { StrapiPromoType } from '../../store/ducks/categoryPromo/categoryPromo';

const SwipeSlider = loadable(() =>
  MiscUtils.loadableRetry(() => import('../../atoms/SwipeSlider/SwipeSlider'), { retries: 3 })
);

interface HomeProps extends RouteComponentProps {
    games: IGame[];
    currentLang: string;
    homepageCategoriesList: CategorySection[];
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    deviceType: DeviceType;
    strapiPromoData?: StrapiPromoType;
}

const SEO_SETTINGS = [
    {
        category: 'solitaire',
        imageType: SeoCardIllustration.FIRST,
        buttonCaption: 'HOME_SEO_SOLITAIER_BUTTON',
        text: 'HOME_SEO_SOLITAIER_TEXT',
    },
    {
        category: 'mahjong',
        imageType: SeoCardIllustration.SECOND,
        buttonCaption: 'HOME_SEO_MAHJONG_BUTTON',
        text: 'HOME_SEO_MAHJONG_TEXT',
    },
    {
        category: 'puzzles',
        imageType: SeoCardIllustration.THIRD,
        buttonCaption: 'HOME_SEO_PUZZLE_BUTTON',
        text: 'HOME_SEO_PUZZLE_TEXT',
    },
];

export class HomeTemplateAdFreeBase extends React.PureComponent<HomeProps> {
    seoRef = React.createRef<HTMLDivElement>();
    page = React.createRef<HTMLDivElement>();

    alreadyTracked: { [key: string]: boolean } = {};

    categoriesList: CategorySection[] = this.props.homepageCategoriesList.map((i) => ({
        ...i,
        gamesListFn: ((gamesList: IGame[]) =>
          i.gameSlugs
            .filter((slug) => gamesList.find((game) => game.slug === slug))
            .map((slug) => gamesList.find((game) => game.slug === slug))) as GamesListFn,
    }));

    getCategorySectionByName(name: string): CategorySection {
        return this.categoriesList.find((s) => s.key.toLowerCase() === name.toLowerCase());
    }

    state = { hydrateRerender: 0 };

    trackVisibleElements = () => {
        const html = document.body.parentElement;

        if (!this.alreadyTracked.seo) {
            const el = this.seoRef.current;

            if (el) {
                const elR = el.getBoundingClientRect();

                if (elR.y + elR.height * 0.5 <= html.clientHeight) {
                    this.alreadyTracked.seo = true;
                    Analytics.trackEvent(Analytics.newHome.seoImpression());
                }
            }
        }

        const pageEl = this.page.current;

        if (!pageEl) {
            return;
        }

        let rows: Element[];
        let dataTheme = 'data-right-rail-theme';

        if (html.clientWidth < 1200) {
            rows = Array.from(pageEl.querySelectorAll(`[data-theme], [data-mobile-theme]`));
            dataTheme = 'data-mobile-theme';
        } else {
            rows = Array.from(pageEl.querySelectorAll(`.${styles.row}`));
        }

        rows.forEach((item, ind) => {
            if (this.alreadyTracked[`row_${ind}`]) {
                return;
            }

            const rect = item.getBoundingClientRect();

            if (rect.y + rect.height * 0.5 <= html.clientHeight) {
                this.alreadyTracked[`row_${ind}`] = true;

                if (item.hasAttribute('data-theme')) {
                    Analytics.trackEvent(
                      Analytics.newHome.promoboxImpression(
                        item.getAttribute('data-theme'),
                        `${item.getAttribute('data-size')}|Body|${ind + 1}`
                      )
                    );
                }

                if (item.hasAttribute(dataTheme)) {
                    const promoTarget = item.getAttribute(dataTheme);
                    const position = parseInt(item.getAttribute('data-right-rail-position'), 10);
                    let promoType = `Ad unit|RightRail|${position}`;

                    if (html.clientWidth < 1200) {
                        promoType = `Ad unit|Body|${ind + 1}`;
                    }

                    Analytics.trackEvent(Analytics.newHome.promoboxImpression(promoTarget, promoType));
                }
            }
        });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.trackVisibleElements);
        this.trackVisibleElements();
        PWAService.getUserVisitPWA(undefined, undefined, true);
        this.setState({ hydrateRerender: 1 });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.trackVisibleElements);
    }

    getLocalizedLink(categorySlug: string) {
        const { currentLang, pages } = this.props;

        return `${CategoryLocalizedRoute[currentLang]}/${
          PageService.getPageSEOByPageName(pages, categorySlug).slug[currentLang]
        }/`;
    }

    openSeeAll(categorySlug: string) {
        const link = this.getLocalizedLink(categorySlug);

        this.props.history.push(link);
    }

    getGameByAlias(alias): IGame {
        return this.props.games.find((g) => StringUtils.equalIgnoreCase(g.alias, alias));
    }

    render() {
        const { games, deviceType, currentLang, pages, history } = this.props;
        const gamesFiltered = GameService.filterGamesByLang(games, currentLang);
        const { strapiPromoData } = this.props;
        const contentCollectionArray =
          strapiPromoData?.contentCollection && Object.values(strapiPromoData?.contentCollection);
        // prepare data to render spotlights
        const spotlightsData = {
            en: {
                promo1:
                  strapiPromoData?.contentSpotlight?.contentSpotlight1 &&
                  strapiPromoData?.contentSpotlight?.contentSpotlight1?.button?.url
                    ? {
                        title: strapiPromoData?.contentSpotlight?.contentSpotlight1?.sectionTitle,
                        caption: strapiPromoData?.contentSpotlight?.contentSpotlight1?.title,
                        description: strapiPromoData?.contentSpotlight?.contentSpotlight1?.text,
                        buttonCaption: strapiPromoData?.contentSpotlight?.contentSpotlight1?.button?.label,
                        game: this.getGameByAlias(
                          UrlService.getSlugFromFullPath(
                            strapiPromoData?.contentSpotlight?.contentSpotlight1?.button?.url
                          )
                        ),
                    }
                    : {},
                promo2:
                  strapiPromoData?.contentSpotlight?.contentSpotlight2 &&
                  strapiPromoData?.contentSpotlight?.contentSpotlight2?.button?.url
                    ? {
                        title: strapiPromoData?.contentSpotlight?.contentSpotlight2?.sectionTitle,
                        caption: strapiPromoData?.contentSpotlight?.contentSpotlight2?.title,
                        description: strapiPromoData?.contentSpotlight?.contentSpotlight2?.text,
                        buttonCaption: strapiPromoData?.contentSpotlight?.contentSpotlight2?.button?.label,
                        game: this.getGameByAlias(
                          UrlService.getSlugFromFullPath(
                            strapiPromoData?.contentSpotlight?.contentSpotlight2?.button?.url
                          )
                        ),
                    }
                    : {},
                promo3:
                  strapiPromoData?.contentSpotlight?.contentSpotlight3 &&
                  strapiPromoData?.contentSpotlight?.contentSpotlight3?.button?.url
                    ? {
                        title: strapiPromoData?.contentSpotlight?.contentSpotlight3?.sectionTitle,
                        caption: strapiPromoData?.contentSpotlight?.contentSpotlight3?.title,
                        description: strapiPromoData?.contentSpotlight?.contentSpotlight3?.text,
                        buttonCaption: strapiPromoData?.contentSpotlight?.contentSpotlight3?.button?.label,
                        game: this.getGameByAlias(
                          UrlService.getSlugFromFullPath(
                            strapiPromoData?.contentSpotlight?.contentSpotlight3?.button?.url
                          )
                        ),
                    }
                    : {},
            },
            // WORKAROUND with some hard code for French, as we are waiting for Strapi locales update
            fr: {
                promo1: {
                    game: this.getGameByAlias('bubble-shooter'),
                    title: 'PROMO_TITLE_BS',
                    caption: 'PROMO_GAME_CAPTION_BS',
                    description: 'PROMO_GAME_DESCRIPTION_BS',
                    buttonCaption: 'Jouer maintenant',
                },
                promo2: {
                    game: this.getGameByAlias('mahjongg-dimensions'),
                    title: 'PROMO_TITLE_MD',
                    caption: 'PROMO_GAME_CAPTION_MD',
                    description: 'PROMO_GAME_DESCRIPTION_MD',
                    buttonCaption: 'Jouer maintenant',
                },
                promo3: {
                    game: this.getGameByAlias('classic-solitaire'),
                    title: 'PROMO_TITLE_CS',
                    caption: 'PROMO_GAME_CAPTION_CS',
                    description: 'PROMO_GAME_DESCRIPTION_CS',
                    buttonCaption: 'Jouer maintenant',
                },
            },
        };


        // const promoBanners = {
        //     en: {
        //         promo1: {
        //             game: this.getGameBySlug('family-feud'),
        //             title: 'PROMO_TITLE_FF',
        //             caption: 'PROMO_GAME_CAPTION_FF',
        //             description: 'PROMO_GAME_DESCRIPTION_FF',
        //         },
        //         promo2: {
        //             game: this.getGameBySlug('price-is-right-plinko'),
        //             title: 'PROMO_TITLE_P',
        //             caption: 'PROMO_GAME_CAPTION_P',
        //             description: 'PROMO_GAME_DESCRIPTION_P',
        //         },
        //         promo3: {
        //             game: this.getGameBySlug('bubble-shooter'),
        //             title: 'PROMO_TITLE_BS',
        //             caption: 'PROMO_GAME_CAPTION_BS',
        //             description: 'PROMO_GAME_DESCRIPTION_BS',
        //         },
        //     },
        //     fr: {
        //         promo1: {
        //             game: this.getGameBySlug('bubble-shooter'),
        //             title: 'PROMO_TITLE_BS',
        //             caption: 'PROMO_GAME_CAPTION_BS',
        //             description: 'PROMO_GAME_DESCRIPTION_BS',
        //         },
        //         promo2: {
        //             game: this.getGameBySlug('mahjongg-dimensions'),
        //             title: 'PROMO_TITLE_MD',
        //             caption: 'PROMO_GAME_CAPTION_MD',
        //             description: 'PROMO_GAME_DESCRIPTION_MD',
        //         },
        //         promo3: {
        //             game: this.getGameBySlug('classic-solitaire'),
        //             title: 'PROMO_TITLE_CS',
        //             caption: 'PROMO_GAME_CAPTION_CS',
        //             description: 'PROMO_GAME_DESCRIPTION_CS',
        //         },
        //     },
        // };

        const strategyGames = ['chess', '5roll', 'mahjong-remix', 'sweet-shuffle'].map((slug) =>
          GameService.findGameByArena5Slug(games, slug)
        );

        return (
          <>
              <HomepageCarousel />
              <main className={styles.main}>
                  <div
                    className={classNames(styles.content, styles.adFree)}
                    ref={this.page}
                    key={this.state.hydrateRerender}
                  >
                      {this.getCategorySectionByName('Arkadium fan favorites')
                        .gamesListFn(gamesFiltered)
                        .filter((game) => !!game).length > 0 && (
                        <>
                            {deviceType !== DeviceType.MOBILE && (
                              <div
                                className={classNames(styles.row, styles.visibleGrid)}
                                data-size="Medium"
                                data-theme="Arkadium Fan Favorites"
                                data-position="1"
                              >
                                  <div className={styles.rowContent}>
                                      <HomepageSlider
                                        items={this.getCategorySectionByName(
                                          'Arkadium fan favorites'
                                        ).gamesListFn(games)}
                                        gameCardType={GameCardTypes.MEDIUM}
                                        promoboxCategory="Arkadium Fan Favorites"
                                        promoBoxPosition="1"
                                        caption={this.getCategorySectionByName('Arkadium fan favorites').name}
                                        headerLink={this.getLocalizedLink('best')}
                                      />
                                  </div>
                              </div>
                            )}

                            {deviceType === DeviceType.MOBILE && (
                              <div
                                className={classNames(styles.row, styles.visibleGrid)}
                                data-size="Small"
                                data-theme="Arkadium Fan Favorites"
                                data-position="1"
                              >
                                  <div className={styles.rowContent}>
                                      <HomepageSlider
                                        items={this.getCategorySectionByName(
                                          'Arkadium fan favorites'
                                        ).gamesListFn(games)}
                                        gameCardType={GameCardTypes.SMALL}
                                        promoboxCategory="Arkadium Fan Favorites"
                                        promoBoxPosition="1"
                                        caption={this.getCategorySectionByName('Arkadium fan favorites').name}
                                        headerLink={this.getLocalizedLink('best')}
                                      />
                                  </div>
                                  <div className={styles.side} />
                              </div>
                            )}
                        </>
                      )}
                      {GameService.getNewCategoryGames(games).length > 0 && (
                        <>
                            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                                <div
                                  className={styles.row}
                                  data-size="Large"
                                  data-theme="New Game Releases"
                                  data-position="2"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={GameService.getNewCategoryGames(games)}
                                          gameCardType={GameCardTypes.LARGE}
                                          promoboxCategory="New Game Releases"
                                          promoBoxPosition="2"
                                          caption={this.getCategorySectionByName('New Game Releases').name}
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'New Game Releases',
                                                  `Body`,
                                                  '2',
                                                  'New Game Releases'
                                                )
                                              );
                                              this.openSeeAll('New');
                                          }}
                                          headerLink={this.getLocalizedLink('new')}
                                        />
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                                <div
                                  className={styles.row}
                                  data-size="small"
                                  data-theme="New Game Releases"
                                  data-position="2"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={GameService.getNewCategoryGames(games)}
                                          gameCardType={GameCardTypes.SMALL}
                                          promoboxCategory="New Game Releases"
                                          promoBoxPosition="2"
                                          caption={this.getCategorySectionByName('New Game Releases').name}
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'New Game Releases',
                                                  `Body`,
                                                  '2',
                                                  'New Game Releases'
                                                )
                                              );
                                              this.openSeeAll('New');
                                          }}
                                          headerLink={this.getLocalizedLink('new')}
                                        />
                                    </div>
                                </div>
                            </Responsive>
                        </>
                      )}
                      {/*//TODO this has french content*/}

                      {spotlightsData[currentLang]?.promo1?.game && (
                        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                            <div
                              className={classNames(styles.row, styles.fullscreen, styles.promoFullscreen)}
                              data-size="Detailed"
                              data-theme="MJ"
                              data-right-rail-theme="support"
                              data-right-rail-position="0"
                              data-position="3"
                            >
                                <div className={styles.rowContent}>
                                    <div className={styles.promoCardContainer}>
                                        <I18nText
                                          as="h2"
                                          className={styles.promoHeading}
                                          keyName={spotlightsData[currentLang].promo1.title}
                                        />
                                        <PromoCardGameItem
                                          game={spotlightsData[currentLang].promo1.game}
                                          caption={spotlightsData[currentLang].promo1.caption}
                                          description={spotlightsData[currentLang].promo1.description}
                                          buttonCaption={spotlightsData[currentLang].promo1.buttonCaption}
                                          position="3"
                                        />
                                    </div>
                                </div>

                                {currentLang === 'fr' ? (
                                  <div className={styles.sideCenter}>
                                      <AdDividerPromoCard
                                        newWindow
                                        url="https://support.arkadium.com/en/support/home"
                                        imagePath={UrlService.toCDNUrl(
                                          '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-help.png'
                                        )}
                                        caption="AD_DIVIDER_HELP_CAPTION"
                                        description="AD_DIVIDER_HELP_TEXT"
                                        buttonCaption="SEE_MORE"
                                        onClick={() => {
                                            Analytics.trackEvent(
                                              Analytics.newHome.promoboxSlotClick(
                                                'support',
                                                `Ad unit|RightRail`,
                                                '0'
                                              )
                                            );
                                        }}
                                      />
                                  </div>
                                ) : (
                                  <div className={styles.sideCenter}>
                                      {strapiPromoData?.contentRail?.contentRail1 &&
                                        strapiPromoData?.contentRail?.contentRail1?.button?.url &&
                                        strapiPromoData?.contentRail?.contentRail1?.image?.url && (
                                          <AdDividerPromoCard
                                            newWindow
                                            url={UrlService.checkIfAbsoluteUrlOrCreate(
                                              strapiPromoData?.contentRail?.contentRail1?.button?.url
                                            )}
                                            imagePath={
                                                strapiPromoData?.contentRail?.contentRail1?.image?.url
                                            }
                                            caption={strapiPromoData?.contentRail?.contentRail1?.title}
                                            description={strapiPromoData?.contentRail?.contentRail1?.text}
                                            buttonCaption={
                                                strapiPromoData?.contentRail?.contentRail1?.button?.label
                                            }
                                            onClick={() => {
                                                Analytics.trackEvent(
                                                  Analytics.newHome.promoboxSlotClick(
                                                    strapiPromoData?.contentRail?.contentRail1
                                                      ?.promoBoxEvent,
                                                    `Ad unit|RightRail`,
                                                    '0'
                                                  )
                                                );
                                            }}
                                          />
                                        )}
                                  </div>
                                )}
                            </div>
                        </Responsive>
                      )}

                      {this.getCategorySectionByName('Daily Games')
                        .gamesListFn(gamesFiltered)
                        .filter((game) => !!game).length > 0 && (
                        <>
                            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                                <div
                                  className={classNames(styles.row, styles.visibleLargeDevices)}
                                  data-theme="Daily Games"
                                  data-size="Medium"
                                  data-position="4"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Daily Games').gamesListFn(games)}
                                          gameCardType={GameCardTypes.MEDIUM}
                                          promoboxCategory="Daily Games"
                                          promoBoxPosition="3"
                                          caption={this.getCategorySectionByName('Daily Games').name}
                                        />
                                    </div>
                                </div>
                            </Responsive>

                            <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                                <div
                                  className={styles.row}
                                  data-theme="Daily Games"
                                  data-size="Small"
                                  data-position="3"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Daily Games').gamesListFn(games)}
                                          gameCardType={GameCardTypes.SMALL}
                                          promoboxCategory="Daily Games"
                                          promoBoxPosition="3"
                                          caption={this.getCategorySectionByName('Daily Games').name}
                                        />
                                    </div>
                                    <div className={styles.side} />
                                </div>
                            </Responsive>
                        </>
                      )}

                      {spotlightsData[currentLang]?.promo1?.game && (
                        <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                            <div
                              className={classNames(styles.row, styles.fullscreen, styles.promoFullscreen)}
                              data-size="Detailed"
                              data-theme="Block Champ"
                              data-position="4"
                            >
                                <div className={styles.rowContent}>
                                    <div className={styles.promoCardContainer}>
                                        <I18nText
                                          as="h2"
                                          className={styles.promoHeading}
                                          keyName={spotlightsData[currentLang].promo1.title}
                                        />
                                        <PromoCardGameItem
                                          game={spotlightsData[currentLang].promo1.game}
                                          caption={spotlightsData[currentLang].promo1.caption}
                                          description={spotlightsData[currentLang].promo1.description}
                                          buttonCaption={spotlightsData[currentLang].promo1.buttonCaption}
                                          position="4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Responsive>
                      )}

                      {this.getCategorySectionByName('Solitaire')
                        .gamesListFn(gamesFiltered)
                        .filter((game) => !!game).length > 0 && (
                        <>
                            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                                <div
                                  className={classNames(styles.row, styles.visibleLargeDevices)}
                                  data-size="Medium"
                                  data-theme="Solitaire"
                                  data-position="5"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Solitaire').gamesListFn(games)}
                                          gameCardType={GameCardTypes.MEDIUM}
                                          promoboxCategory="Solitaire"
                                          promoBoxPosition="4"
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Solitaire',
                                                  `Body`,
                                                  '5',
                                                  'Solitaire'
                                                )
                                              );
                                              this.openSeeAll('Solitaire');
                                          }}
                                          caption={this.getCategorySectionByName('Solitaire').name}
                                          headerLink={this.getLocalizedLink('solitaire')}
                                        />
                                    </div>
                                </div>
                            </Responsive>

                            <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                                <div
                                  className={styles.row}
                                  data-size="Small"
                                  data-theme="Solitaire"
                                  data-position="5"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Solitaire').gamesListFn(games)}
                                          gameCardType={GameCardTypes.SMALL}
                                          promoboxCategory="Solitaire"
                                          promoBoxPosition="5"
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Solitaire',
                                                  `Body`,
                                                  '5',
                                                  'Solitaire'
                                                )
                                              );
                                              this.openSeeAll('Solitaire');
                                          }}
                                          caption={this.getCategorySectionByName('Solitaire').name}
                                          headerLink={this.getLocalizedLink('solitaire')}
                                        />
                                    </div>
                                </div>
                            </Responsive>
                        </>
                      )}

                      <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                          <div className={styles.row} data-size="Small" data-theme="Strategy" data-position="6">
                              <div className={styles.rowContent}>
                                  <HomepageSlider
                                    promoboxCategory="Strategy"
                                    promoBoxPosition="6"
                                    items={strategyGames}
                                    gameCardType={GameCardTypes.SMALL}
                                    onSeeAllClick={() => {
                                        Analytics.trackEvent(
                                          Analytics.newHome.promoboxSlotClick('Strategy', `Body|6`, 'Strategy')
                                        );
                                        this.openSeeAll('Strategy');
                                    }}
                                    caption="Strategy"
                                    headerLink={this.getLocalizedLink('strategy')}
                                  />
                              </div>
                          </div>
                      </Responsive>

                      {spotlightsData[currentLang]?.promo2?.game && (
                        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                            <div
                              className={classNames(styles.row, styles.fullscreen, styles.promoFullscreen)}
                              data-size="Detailed"
                              data-theme="DS"
                              data-right-rail-theme="solitaireTips"
                              data-right-rail-position="1"
                              data-position="6"
                            >
                                <div className={styles.rowContent}>
                                    <div className={styles.promoCardContainer}>
                                        <I18nText
                                          as="h2"
                                          className={styles.promoHeading}
                                          keyName={spotlightsData[currentLang].promo2.title}
                                        />
                                        <PromoCardGameItem
                                          game={spotlightsData[currentLang].promo2.game}
                                          caption={spotlightsData[currentLang].promo2.caption}
                                          description={spotlightsData[currentLang].promo2.description}
                                          buttonCaption={spotlightsData[currentLang].promo2.buttonCaption}
                                          position="6"
                                        />
                                    </div>
                                </div>

                                {currentLang === 'fr' ? (
                                  <div className={styles.sideCenter}>
                                      <AdDividerPromoCard
                                        newWindow
                                        url="https://support.arkadium.com/en/support/search?term=Solitaire"
                                        imagePath={UrlService.toCDNUrl(
                                          '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-solitaire.png'
                                        )}
                                        caption="AD_DIVIDER_SOLITAIRE_CAPTION"
                                        description="AD_DIVIDER_SOLITAIRE_TEXT"
                                        buttonCaption="READ_MORE"
                                        onClick={() => {
                                            Analytics.trackEvent(
                                              Analytics.newHome.promoboxSlotClick(
                                                'solitaireTips',
                                                `Ad unit|Body`,
                                                '1'
                                              )
                                            );
                                        }}
                                      />
                                  </div>
                                ) : (
                                  <div className={styles.sideCenter}>
                                      {strapiPromoData?.contentRail?.contentRail2 &&
                                        strapiPromoData?.contentRail?.contentRail2?.button?.url &&
                                        strapiPromoData?.contentRail?.contentRail2?.image?.url && (
                                          <AdDividerPromoCard
                                            newWindow
                                            url={UrlService.checkIfAbsoluteUrlOrCreate(
                                              strapiPromoData?.contentRail?.contentRail2?.button?.url
                                            )}
                                            imagePath={
                                                strapiPromoData?.contentRail?.contentRail2?.image?.url
                                            }
                                            caption={strapiPromoData?.contentRail?.contentRail2?.title}
                                            description={strapiPromoData?.contentRail?.contentRail2?.text}
                                            buttonCaption={
                                                strapiPromoData?.contentRail?.contentRail2?.button?.label
                                            }
                                            onClick={() => {
                                                Analytics.trackEvent(
                                                  Analytics.newHome.promoboxSlotClick(
                                                    strapiPromoData?.contentRail?.contentRail2
                                                      ?.promoBoxEvent,
                                                    `Ad unit|Body`,
                                                    '1'
                                                  )
                                                );
                                            }}
                                          />
                                        )}
                                  </div>
                                )}
                            </div>
                        </Responsive>
                      )}

                      {this.getCategorySectionByName('Popular Now')
                        .gamesListFn(gamesFiltered)
                        .filter((game) => !!game).length > 0 && (
                        <>
                            <Responsive minWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP}>
                                <div
                                  className={styles.row}
                                  data-size="Super Large"
                                  data-theme="Popular Now"
                                  data-position="7"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Popular Now').gamesListFn(games)}
                                          gameCardType={GameCardTypes.SUPER_LARGE}
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Popular Now',
                                                  `Body`,
                                                  '7',
                                                  'Popular Now'
                                                )
                                              );
                                              this.openSeeAll('Best');
                                          }}
                                          caption={this.getCategorySectionByName('Popular Now').name}
                                          promoboxCategory="Popular Now"
                                          promoBoxPosition="7"
                                          headerLink={this.getLocalizedLink('best')}
                                        />
                                    </div>
                                </div>
                            </Responsive>

                            <Responsive
                              minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_BELOW}
                              maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                            >
                                <div className={styles.row} data-theme="Popular Now" data-size="Large">
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Popular Now').gamesListFn(games)}
                                          gameCardType={GameCardTypes.LARGE}
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Popular Now',
                                                  `Body`,
                                                  '7',
                                                  'Popular Now'
                                                )
                                              );
                                              this.openSeeAll('Best');
                                          }}
                                          caption={this.getCategorySectionByName('Popular Now').name}
                                          promoboxCategory="Popular Now"
                                          promoBoxPosition="6"
                                          headerLink={this.getLocalizedLink('best')}
                                        />
                                    </div>
                                    <div className={styles.side} />
                                </div>
                            </Responsive>

                            <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_BELOW}>
                                <div className={styles.row} data-theme="Popular Now" data-size="Small">
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Popular Now').gamesListFn(games)}
                                          gameCardType={GameCardTypes.SMALL}
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Popular Now',
                                                  `Body`,
                                                  '7',
                                                  'Popular Now'
                                                )
                                              );
                                              this.openSeeAll('Best');
                                          }}
                                          caption={this.getCategorySectionByName('Popular Now').name}
                                          promoboxCategory="Popular Now"
                                          promoBoxPosition="7"
                                          headerLink={this.getLocalizedLink('best')}
                                        />
                                    </div>
                                    <div className={styles.side} />
                                </div>
                            </Responsive>
                        </>
                      )}

                      {spotlightsData[currentLang]?.promo3?.game && (
                        <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                            <div
                              className={classNames(styles.row, styles.fullscreen, styles.promoFullscreen)}
                              data-size="Detailed"
                              data-theme="DS"
                              data-position="8"
                            >
                                <div className={styles.rowContent}>
                                    <div className={styles.promoCardContainer}>
                                        <I18nText
                                          as="h2"
                                          className={styles.promoHeading}
                                          keyName={spotlightsData[currentLang].promo3.title}
                                        />
                                        <PromoCardGameItem
                                          game={spotlightsData[currentLang].promo3.game}
                                          caption={spotlightsData[currentLang].promo3.caption}
                                          description={spotlightsData[currentLang].promo3.description}
                                          buttonCaption={spotlightsData[currentLang].promo3.buttonCaption}
                                          position="8"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Responsive>
                      )}

                      {this.getCategorySectionByName('Crosswords')
                        .gamesListFn(gamesFiltered)
                        .filter((game) => !!game).length > 0 && (
                        <>
                            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                                <div
                                  className={classNames(styles.row)}
                                  data-size="Medium"
                                  data-theme="Crosswords"
                                  data-position="8"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Crosswords').gamesListFn(games)}
                                          gameCardType={GameCardTypes.MEDIUM}
                                          promoboxCategory="Crosswords"
                                          promoBoxPosition="8"
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Crosswords',
                                                  `Body`,
                                                  '8',
                                                  'Crosswords'
                                                )
                                              );
                                              this.openSeeAll('Crosswords');
                                          }}
                                          caption={this.getCategorySectionByName('Crosswords').name}
                                          headerLink={this.getLocalizedLink('crosswords')}
                                        />
                                    </div>
                                </div>
                            </Responsive>

                            <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                                <div
                                  className={styles.row}
                                  data-size="Small"
                                  data-theme="Crosswords"
                                  data-position="9"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Crosswords').gamesListFn(games)}
                                          gameCardType={GameCardTypes.SMALL}
                                          promoboxCategory="Crosswords"
                                          promoBoxPosition="9"
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Crosswords',
                                                  `Body`,
                                                  '9',
                                                  'Crosswords'
                                                )
                                              );
                                              this.openSeeAll('Crosswords');
                                          }}
                                          caption={this.getCategorySectionByName('Crosswords').name}
                                          headerLink={this.getLocalizedLink('crosswords')}
                                        />
                                    </div>
                                </div>
                            </Responsive>
                        </>
                      )}

                      {spotlightsData[currentLang]?.promo3?.game && (
                        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                            <div
                              className={classNames(styles.row, styles.fullscreen, styles.promoFullscreen)}
                              data-size="Detailed"
                              data-theme="Bubble Shooter"
                              data-right-rail-theme="staySharp"
                              data-right-rail-position="2"
                              data-position="9"
                            >
                                <div className={styles.rowContent}>
                                    <div className={styles.promoCardContainer}>
                                        <I18nText
                                          as="h2"
                                          className={styles.promoHeading}
                                          keyName={spotlightsData[currentLang].promo3.title}
                                        />
                                        <PromoCardGameItem
                                          game={spotlightsData[currentLang].promo3.game}
                                          caption={spotlightsData[currentLang].promo3.caption}
                                          description={spotlightsData[currentLang].promo3.description}
                                          buttonCaption={spotlightsData[currentLang].promo3.buttonCaption}
                                          position="9"
                                        />
                                    </div>
                                </div>

                                {currentLang !== 'fr' && (
                                  <div className={styles.sideCenter}>
                                      {strapiPromoData?.contentRail?.contentRail3 &&
                                        strapiPromoData?.contentRail?.contentRail3?.button?.url &&
                                        strapiPromoData?.contentRail?.contentRail3?.image?.url && (
                                          <AdDividerPromoCard
                                            url={UrlService.checkIfAbsoluteUrlOrCreate(
                                              strapiPromoData?.contentRail?.contentRail3?.button?.url
                                            )}
                                            imagePath={
                                                strapiPromoData?.contentRail?.contentRail3?.image?.url
                                            }
                                            caption={strapiPromoData?.contentRail?.contentRail3?.title}
                                            description={strapiPromoData?.contentRail?.contentRail3?.text}
                                            buttonCaption={
                                                strapiPromoData?.contentRail?.contentRail3?.button?.label
                                            }
                                            adDividerClassName={styles.adDividerClassNameSharp}
                                            onClick={() => {
                                                Analytics.trackEvent(
                                                  Analytics.newHome.promoboxSlotClick(
                                                    strapiPromoData?.contentRail?.contentRail3
                                                      ?.promoBoxEvent,
                                                    `Ad unit|RightRail`,
                                                    '2'
                                                  )
                                                );
                                            }}
                                          />
                                        )}
                                  </div>
                                )}
                            </div>
                        </Responsive>
                      )}

                      {this.getCategorySectionByName('Word Games')
                        .gamesListFn(gamesFiltered)
                        .filter((game) => !!game).length > 0 && (
                        <>
                            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                                <div
                                  className={styles.row}
                                  data-size="Large"
                                  data-theme="Word Games"
                                  data-position="10"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Word Games').gamesListFn(games)}
                                          gameCardType={GameCardTypes.LARGE}
                                          promoboxCategory="Word Games"
                                          promoBoxPosition="8"
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Word Games',
                                                  `Body`,
                                                  '10',
                                                  'Word Games'
                                                )
                                              );
                                              this.openSeeAll('Crosswords');
                                          }}
                                          caption={this.getCategorySectionByName('Word Games').name}
                                          headerLink={this.getLocalizedLink('word')}
                                        />
                                    </div>
                                </div>
                            </Responsive>

                            <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                                <div
                                  className={styles.row}
                                  data-size="Small"
                                  data-theme="Word Games"
                                  data-position="10"
                                >
                                    <div className={styles.rowContent}>
                                        <HomepageSlider
                                          items={this.getCategorySectionByName('Word Games').gamesListFn(games)}
                                          gameCardType={GameCardTypes.SMALL}
                                          promoboxCategory="Word Games"
                                          promoBoxPosition="8"
                                          onSeeAllClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  'Word Games',
                                                  `Body`,
                                                  '10',
                                                  'Word Games'
                                                )
                                              );
                                              this.openSeeAll('Crosswords');
                                          }}
                                          caption={this.getCategorySectionByName('Word Games').name}
                                          headerLink={this.getLocalizedLink('word')}
                                        />
                                    </div>
                                </div>
                            </Responsive>
                        </>
                      )}

                      {this.getCategorySectionByName('Word Games') // show only if word games row exist
                        .gamesListFn(gamesFiltered)
                        .filter((game) => !!game).length > 0 && (
                        <div
                          className={classNames(styles.row, styles.fullscreen, styles.promoFullscreen)}
                          data-size="Detailed"
                          data-theme="All games"
                          data-right-rail-theme="games"
                          data-right-rail-position="3"
                          data-position="11"
                        >
                            <div className={styles.rowContent}>
                                <div className={styles.promoCardContainer}>
                                    {strapiPromoData?.contentSpotlight?.contentSpotlight4 &&
                                      strapiPromoData?.contentSpotlight?.contentSpotlight4?.button?.url &&
                                      strapiPromoData?.contentSpotlight?.contentSpotlight4?.image?.url && (
                                        <PromoCard
                                          image={
                                              strapiPromoData?.contentSpotlight?.contentSpotlight4?.image?.url
                                          }
                                          imageWebp={
                                              strapiPromoData?.contentSpotlight?.contentSpotlight4?.image?.url
                                          }
                                          description={
                                              strapiPromoData?.contentSpotlight?.contentSpotlight4?.text
                                          }
                                          buttonCaption={
                                              strapiPromoData?.contentSpotlight?.contentSpotlight4?.button
                                                ?.label
                                          }
                                          url={UrlService.createURL(
                                            strapiPromoData?.contentSpotlight?.contentSpotlight4?.button
                                              ?.url
                                          )}
                                          onButtonClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                  strapiPromoData?.contentSpotlight?.contentSpotlight4
                                                    ?.promoBoxEvent,
                                                  'Detailed|Body',
                                                  '11',
                                                  UrlService.getSlugFromPathname(
                                                    strapiPromoData?.contentSpotlight?.contentSpotlight4
                                                      ?.promoBoxEvent
                                                  )
                                                )
                                              );
                                          }}
                                        />
                                      )}
                                </div>
                            </div>
                            <div className={styles.sideCenter}>
                                {strapiPromoData?.contentRail?.contentRail4 &&
                                  strapiPromoData?.contentRail?.contentRail4?.button?.url &&
                                  strapiPromoData?.contentRail?.contentRail4?.image?.url && (
                                    <AdDividerPromoCard
                                      newWindow
                                      url={UrlService.checkIfAbsoluteUrlOrCreate(
                                        strapiPromoData?.contentRail?.contentRail4?.button?.url
                                      )}
                                      imagePath={strapiPromoData?.contentRail?.contentRail4?.image?.url}
                                      caption={strapiPromoData?.contentRail?.contentRail4?.title}
                                      description={strapiPromoData?.contentRail?.contentRail4?.text}
                                      buttonCaption={
                                          strapiPromoData?.contentRail?.contentRail4?.button?.label
                                      }
                                      adDividerClassName={styles.adDividerClassNameLicensing}
                                      onClick={() => {
                                          Analytics.trackEvent(
                                            Analytics.newHome.promoboxSlotClick(
                                              strapiPromoData.contentRail?.contentRail4?.promoBoxEvent,
                                              `Ad unit|RightRail`,
                                              '3'
                                            )
                                          );
                                      }}
                                    />
                                  )}
                            </div>
                        </div>
                      )}
                  </div>
              </main>

              {currentLang === 'fr' ? (
                <div className={styles.seo} ref={this.seoRef}>
                    <I18nText as="h2" className={styles.homeSeoTitle} keyName="HOME_SEO_CAPTION" />
                    <I18nText as="p" className={styles.homeSeoSubTitle} keyName="HOME_SEO_DESCRIPTION" />
                    <Responsive maxWidth={1023}>
                        <SwipeSlider
                          items={SEO_SETTINGS}
                          noShadows
                          renderItem={(item, index) => (
                            <SeoCard
                              key={index}
                              illustrationType={item.imageType}
                              image={item.imageType}
                              buttonCaption={item.buttonCaption}
                              buttonType={item.buttonType}
                              text={item.text}
                              onClick={() => {
                                  Analytics.trackEvent(Analytics.newHome.seoClick(item.category));
                                  history.push(
                                    UrlService.createURL(
                                      `${UrlService.getPageLocalizedRoute(
                                        UrlService.currentLang,
                                        PageTypes.Category
                                      )}/${
                                        PageService.getPageSEOByPageName(pages, item.category).slug[
                                          currentLang
                                          ]
                                      }/`
                                    )
                                  );
                              }}
                            />
                          )}
                        />
                    </Responsive>
                    <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
                        <div className={styles.seogrid}>
                            {SEO_SETTINGS.map((it) => (
                              <div className={styles.seoItem} key={it.category}>
                                  <SeoCard
                                    illustrationType={it.imageType}
                                    image={it.imageType}
                                    buttonCaption={it.buttonCaption}
                                    text={it.text}
                                    onClick={() => {
                                        Analytics.trackEvent(Analytics.newHome.seoClick(it.category));
                                        history.push(
                                          UrlService.createURL(
                                            `${UrlService.getPageLocalizedRoute(
                                              UrlService.currentLang,
                                              PageTypes.Category
                                            )}/${
                                              PageService.getPageSEOByPageName(pages, it.category).slug[
                                                currentLang
                                                ]
                                            }/`
                                          )
                                        );
                                    }}
                                  />
                              </div>
                            ))}
                        </div>
                    </Responsive>
                </div>
              ) : (
                <div className={styles.seo} ref={this.seoRef}>
                    <I18nText
                      as="h2"
                      className={styles.homeSeoTitle}
                      keyName={strapiPromoData?.contentCollection?.contentCollection1?.sectionTitle}
                    />
                    <I18nText
                      as="p"
                      className={styles.homeSeoSubTitle}
                      keyName={strapiPromoData?.contentCollection?.contentCollection1?.title}
                    />
                    <Responsive maxWidth={1023}>
                        {contentCollectionArray?.length > 0 && (
                          <SwipeSlider
                            items={contentCollectionArray}
                            noShadows
                            renderItem={(item, index) =>
                              item?.promoBoxEvent &&
                              item?.image?.url && (
                                <SeoCard
                                  key={index}
                                  image={item?.image?.url}
                                  buttonCaption={item?.button?.label}
                                  buttonType={item?.buttonType}
                                  text={item?.text}
                                  onClick={() => {
                                      Analytics.trackEvent(
                                        Analytics.newHome.seoClick(item?.promoBoxEvent)
                                      );
                                      history.push(
                                        UrlService.createURL(
                                          `${CategoryLocalizedRoute[currentLang]}/${
                                            PageService.getPageSEOByPageName(
                                              pages,
                                              item?.promoBoxEvent
                                            ).slug[currentLang]
                                          }/`
                                        )
                                      );
                                  }}
                                />
                              )
                            }
                          />
                        )}
                    </Responsive>
                    <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
                        {contentCollectionArray?.length > 0 && (
                          <div className={styles.seogrid}>
                              {contentCollectionArray?.map(
                                (item) =>
                                  item?.promoBoxEvent &&
                                  item?.image?.url && (
                                    <div className={styles.seoItem} key={item?.promoBoxEvent}>
                                        <SeoCard
                                          image={item?.image?.url}
                                          buttonCaption={item?.button?.label}
                                          text={item?.text}
                                          onClick={() => {
                                              Analytics.trackEvent(
                                                Analytics.newHome.seoClick(item?.promoBoxEvent)
                                              );
                                              history.push(
                                                UrlService.createURL(
                                                  `${CategoryLocalizedRoute[currentLang]}/${
                                                    PageService.getPageSEOByPageName(
                                                      pages,
                                                      item?.promoBoxEvent
                                                    ).slug[currentLang]
                                                  }/`
                                                )
                                              );
                                          }}
                                        />
                                    </div>
                                  )
                              )}
                          </div>
                        )}
                    </Responsive>
                </div>
              )}
          </>
        );
    }
}

type promoCardGameItemProps = {
    game: IGame;
    caption: string;
    description: string;
    buttonCaption: string;
    position: string;
};

const PromoCardGameItem = React.memo(
  ({ game, caption, description, buttonCaption, position }: promoCardGameItemProps) => {
      // if game is not found, we don't render promo card
      if (!game) {
          return <></>;
      }

      const { alias, promoTile1000x312, promoTile1000x312webp, name } = game;

      return (
        <PromoCard
          url={UrlService.createURL(`games/${alias}/`)}
          image={promoTile1000x312}
          imageWebp={promoTile1000x312webp}
          caption={caption}
          description={description}
          buttonCaption={buttonCaption}
          promoCardClassName={styles.promoDividerClassName}
          onButtonClick={() => {
              Analytics.trackEvent(
                Analytics.newHome.promoboxSlotClick(name, `Detailed|Body`, position, alias)
              );
          }}
        />
      );
  }
);
const mapStateToProps = (state) => {
    return {
        strapiPromoData: state.strapiPromoData,
    };
};

export const HomeTemplateAdFree = withRouter(connect(mapStateToProps)(HomeTemplateAdFreeBase));


